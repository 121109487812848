import React from "react";
import {faBrain, faMapMarkedAlt, faThumbsUp} from "@fortawesome/free-solid-svg-icons"
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

import Footer from "../components/Layout/Footer/Footer";
import Layout from "../components/Layout/layout";
import Menu from "../components/Layout/Menu/Menu";
import WhyUs from "../components/Layout/About/WhyUs";
import Question from "../components/Layout/Page/Question";

import mechanicOk from "../images/mechanic-ok.jpg";
import {Link} from "gatsby";
import SchemaOrg from "../components/SEO/SchemaOrg";

export default function AboutPage() {
    return <Layout>
        <GatsbySeo
            title='Poznaj nasz warsztat'
            description='Ponad 30 lat doświadczenia w branży motoryzacyjnej! Jeżeli szukasz profesjonalnego i zaufanego warsztatu samochodowego to dobrze trafiłeś!'
            canonical='https://serwis-olaszewski.pl/o-nas'
            openGraph={{
                title: 'Poznaj nasz warsztat | Auto Serwis Olaszewski',
                url: 'https://serwis-olaszewski.pl/o-nas',
                description: 'Ponad 30 lat doświadczenia w branży motoryzacyjnej! Jeżeli szukasz profesjonalnego i zaufanego warsztatu samochodowego to dobrze trafiłeś!'
            }}
            twitter={{
                title: 'Poznaj nasz warsztat | Auto Serwis Olaszewski',
                description: 'Ponad 30 lat doświadczenia w branży motoryzacyjnej! Jeżeli szukasz profesjonalnego i zaufanego warsztatu samochodowego to dobrze trafiłeś!'
            }}
        />

        <SchemaOrg/>

        <Menu/>

        <main className="has-margin-top">
            <section className="mb-5">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column has-text-right-desktop has-text-justified-mobile pr-6">
                            <h1 className="title">Ponad 30 lat doświadczenia w branży motoryzacyjnej!</h1>
                            <h3 className="subtitle">
                                Jeżeli szukasz profesjonalnego i zaufanego warsztatu samochodowego to dobrze trafiłeś!
                            </h3>

                            <p>
                                <strong className="has-text-dark">Auto Serwis Olaszewski</strong> oferuje usługi w
                                zakresie diagnostyki, napraw i regulacji, najpopularniejszych marek samochodów, na
                                terenie Trzebini.
                            </p>
                            <p>
                                Dzięki niezbędnym użądzeniom, potrzebnym do diagnostyki i naprawy, zyskaliśmy miano
                                profesjonalnego warszatu samochodowego, który prowadzimy <strong>nieustannie od 1997
                                roku</strong>.
                            </p>
                        </div>
                        <div className="column">
                            <img src={mechanicOk} alt="Doświadczenie"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section has-background-info">
                <div className="container">
                    <h1 className="title has-text-dark">Dlaczego my?</h1>

                    <div className="columns">
                        <div className="column">
                            <WhyUs title="Doświadczenie" icon={faBrain}>
                                Posiadamy ponad 30 lat w diagnozowaniu i usuwaniu usterek najpoplularniejszych marek
                                samochodów.
                                Dzięki ciągłym szkoleniom i certyfikacjom, możemy świadczyć usługi
                                diagnostyczno-naprawcze na najwyższym poziomie.
                            </WhyUs>
                        </div>
                        <div className="column">
                            <WhyUs title="Lokalizacja" icon={faMapMarkedAlt}>
                                Nasz warsztat znajduje się w samym centrum Trzebini.
                                W pobliżu znajduje się przystanek autobusowy oraz postój taksówek.
                                Dzięki temu dojazd do poliskiego Chrzanowa lub na obrzeża Trzebini jest prosty i bardzo
                                wygodny.
                            </WhyUs>
                        </div>
                        <div className="column">
                            <WhyUs title="Zaufanie" icon={faThumbsUp}>
                                Zostaliśmy doceneni przez klientów, dzięki temu, że montujemy tylko sprawdzone części
                                samochodowe, od autoryzowanych hurtowni.
                                Tysiące naprawionych samochodów oraz grono zadowolonych klientów nie może się mylić!
                            </WhyUs>
                        </div>
                    </div>
                </div>
            </section>

            <Question title="Masz pytania dotyczące napraw, użytkownia lub serwisowania samochodu?">
                Zapraszamy do <Link to="/kontakt" className="has-text-dark">kontaktu</Link>, udzielimy wyczerpujących i
                profesjonalnych porad!
            </Question>
        </main>

        <Footer/>
    </Layout>
}

import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const WhyUs = ({title, icon, children}) => {
    return <div className="box">
        <article className="media">
            <div className="media-left">
                <FontAwesomeIcon icon={icon} className="fa-lg"/>
            </div>
            <div className="media-content">
                <div className="content">
                    <p>
                        <strong>{title}</strong>
                        <br/>
                        {children}
                    </p>
                </div>
            </div>
        </article>
    </div>
};

WhyUs.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
}

export default WhyUs;
